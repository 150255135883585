.Highlights {
  margin: 75px 0 120px 0;
  font-weight: 700;
  line-height: 1.2;
}

.Highlights .Content > div {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}

.Highlights img {
  display: block;
  width: 100%;
  margin-bottom: 30px;
}

.Highlights h2,
.Highlights p {
  text-align: center;
  width: 100%;
}

.Highlights h2 {
  margin: 0;
  line-height: 39px;
  font-size: 32px;
  font-weight: 700;
  color: #121212;
}

.Highlights p {
  width: 100%;
  margin: 16px 0 56px 0;
  line-height: 17px;
  font-size: 14px;
  font-weight: 500;
  color: #303030;
}

.Highlights .intranet-button {
  display: block;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 26px;
}

.Highlights .mailingButton {
  display: none;
}

@media(min-width: 1000px) {
  .Highlights {
    margin: 100px 0 0 0;
  }

  .Highlights .Content > div {
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #dadada;
  }

  .Highlights div:not(.mailingButton) > .intranet-button {
    display: none;
  }

  .Highlights .Content > div > div:first-child{
    transform: translateY(-35%);
  }

  .Highlights img {
    transform: translateX(-40px);
    margin-bottom: 0;
  }


  .Highlights h2,
  .Highlights p {
    text-align: left;
    width: 628px;
    max-width: 100%;
  }

  .Highlights h2 {
    font-size: 64px;
    line-height: 1;
  }

  .Highlights p {
    width: 519px;
    margin: 10px 0 40px 0;
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
    color: #303030;
  }

  .Highlights .mailingButton {
    display: flex;
    flex-wrap: wrap;
    width: 519px;
    max-width: 100%;
    height: 23px;
  }

  .Highlights .mailingButton .inputField {
    display: inline-flex;
    position: relative;
    width: calc(70% - 2px);
    height: 100%;
    padding: 0;
    border: 0;
  }

  .Highlights .mailingButton .inputField label {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(10px, -50%);
    padding: 5px 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    background: rgb(250,250,250);
    background: linear-gradient(180deg, rgba(250,250,250,1) 50%, rgba(255,255,255,1) 50%);
    color:#000;
    z-index: 10;
  }

  .Highlights .mailingButton .inputField input {
    display: block;
    width: 100%;
    height: 100%;
    padding: 14.5px 14px;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color:#BDBDBD;
    border: 1px solid #00000038;
    border-right: 0;
    border-radius: 8px 0 0 8px;
    -moz-border-radius: 8px 0 0 8px;
    -webkit-border-radius: 8px 0 0 8px;
    background: #fff;
    cursor: text;
  }

  .Highlights .mailingButton .inputField input:focus-visible {
    outline: 0;
    border: 1px solid #014173;
  }

  .Highlights .mailingButton .inputField input:focus::placeholder {
    color: transparent;
  }

  .Highlights .mailingButton .intranet-button {
    display: inline-flex;
    justify-content: center;
    width: 155px;
    max-width: 30%;
    height: 100%;
    text-align: center;
    text-transform: uppercase;
    font-size: 11px;
    line-height: 25px;
    font-weight: 600;
    border: 1px solid #014173;
    border-left: 0;
    border-radius: 0 4px 4px 0;
    -moz-border-radius: 0 4px 4px 0;
    -webkit-border-radius: 0 4px 4px 0;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2);
    cursor: pointer !important;
  }

  .Highlights .mailingButton p {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    font-size: 12px;
    line-height: 1.5;
    font-weight: 600;
    color: #f6853f;
  }
}