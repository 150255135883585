body {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  background: #fafafa;
  cursor: default;
}

body > div > * {
  width: 100%;
}

.Content {
  display:block;
  width: 100%;
  padding: 0 16px;
  box-sizing: border-box;
  -ms-box-sizing: border-box; 
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.intranet-green {
  color: #6bde42;
}

.intranet-orange {
  color: #f6853f;
}

.intranet-blue {
  background: #183561;
}
.sticky {
  position: sticky;
  width: 100%;
  top: 0;
  margin: 0 auto;
  z-index: 1000;
  background: #fafafa;
}

@media(min-width: 800px) {
  .Content {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 80px;
  }
}