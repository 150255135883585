.SendMail {
  padding: 120px 0;
  font-weight: 700;
  line-height: 1.2;
  background: #fff;
}

.SendMail .Content > div {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}

.SendMail h2,
.SendMail p {
  text-align: left;
  width: 100%;
}

.SendMail h2 {
  margin: 0;
  line-height: 29px;
  font-size: 24px;
  font-weight: 700;
  color: #121212;
}

.SendMail p {
  width: 100%;
  margin: 16px 0 56px 0;
  line-height: 17px;
  font-size: 14px;
  font-weight: 500;
  color: #303030;
}

.SendMail .intranet-button {
  display: block;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 26px;
}

.SendMail .mailingButton {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 23px;
}

.SendMail .mailingButton .inputField {
  display: inline-flex;
  position: relative;
  width: calc(100% - 57px);
  height: 100%;
  padding: 0;
  border: 0;
}

.SendMail .mailingButton .inputField label {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(10px, -50%);
  padding: 5px 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  background: rgb(250,250,250);
  background: linear-gradient(180deg, rgba(250,250,250,1) 50%, rgba(255,255,255,1) 50%);
  color:#000;
  z-index: 10;
}

.SendMail .mailingButton .inputField input {
  display: block;
  width: 100%;
  height: 100%;
  padding: 14.5px 14px;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color:#BDBDBD;
  border: 1px solid #00000038;
  border-right: 0;
  border-radius: 8px 0 0 8px;
  -moz-border-radius: 8px 0 0 8px;
  -webkit-border-radius: 8px 0 0 8px;
  background: #fff;
  cursor: text;
}

.SendMail .mailingButton .inputField input:focus-visible {
  outline: 0;
  border: 1px solid #014173;
}

.SendMail .mailingButton .inputField input:focus::placeholder {
  color: transparent;
}

.SendMail .mailingButton .intranet-button {
  display: inline-flex;
  justify-content: center;
  width: 55px;
  height: 100%;
  text-align: center;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 25px;
  font-weight: 600;
  border: 1px solid #014173;
  border-left: 0;
  border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  -webkit-border-radius: 0 4px 4px 0;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2);
  cursor: pointer !important;
}

.SendMail .mailingButton p {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  font-size: 12px;
  line-height: 1.5;
  font-weight: 600;
  color: #f6853f;
}

@media(min-width: 1000px) {
  .SendMail {
    display: none;
  }
}