.Footer {
  width: 100%;
  padding: 70px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

footer .Content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

footer .Content > div:last-child {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

footer .footerLogo {
  display: none;
  order: 3;
}

footer .copyright {
  order: 2;
  font-weight: 700;
  font-size: 12px;
  line-height: 1.2;
}

footer .socialMedia {
  order: 1;
  margin: 0 0 56px 0;
}

footer .Content .socialMedia .intranet-button {
  padding: 20px !important;
  margin: 0 20px;
}

@media(min-width: 800px) {
  .Footer {
    padding: 80px 0;
    border-top: 1px solid #dfdfe0;
  }

  footer .Content > div:last-child {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  footer .Content > div:last-child > * {
    flex: 1 1 30%;
  }

  footer .copyright {
    font-weight: 700;
    font-size: 14px;
  }

  footer .footerLogo {
    display: flex;
  }

  footer .socialMedia .intranet-button {
    padding: initial !important;
    margin: initial;
  }

  footer .socialMedia {
    order: 2;
    width: auto;
    margin: 0;
  }

  footer .footerLogo {
    order: 3;
    display: flex;
    justify-content: flex-end;
  }
}