.Explanation {
  padding: 120px 0 76px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.Explanation h2 {
  padding: 0 12px;
  font-size: 32px;
  font-weight: 600;
  line-height: 1.2;
}

.explanation-text {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin: 40px 0;
}

.explanation-text > * {
  flex: 1 1 33%;
}

.Explanation .BusinessCard {
  position: relative;
  margin: 44px 0;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  font-family: "Inter", sans-serif;
  font-weight: 700;
}

.Explanation .BusinessCard img {
  max-width: 50%;
}

.Explanation .BusinessCard h3 {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 24px;
  line-height: 1;
  color: #191919;
}

.Explanation .BusinessCard p {
  margin:0;
  padding: 16px 0 0 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.1;
  color: #303030;
}

@media(min-width: 800px) {
  .Explanation {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .Explanation h2 {
    margin: 0 auto;
    max-width: 1062px;
    font-size: 56px;
    font-weight: 600;
    line-height: 1;
  }
  
  .explanation-text {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    align-content: baseline;
    justify-content: baseline;
    margin: 64px 0;
  }
  
  .explanation-text > * {
    flex: 1 1 33%;
  }

  .Explanation .BusinessCard {
    margin: 0 40px 0 0;
  }

  .Explanation .BusinessCard:last-of-type {
    margin-right: 0;
  }

  .Explanation .BusinessCard img {
    max-width: initial;
  }

  .Explanation .BusinessCard h3 {
    font-weight: 700;
    font-size: 32px;
    color: #183561;
  }
  
  .Explanation .BusinessCard p {
    font-size: 16px;
    line-height: 1.5;
  }
}