.PageServices {
  width: 100%;
  padding: 56px 0 48px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  background: #f2f2f2;
}

.PageServices .Content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-flow: wrap;
  align-items: flex-start;
  text-align: center;
}


.PageServices h2 {
  order: 1;
  width: 100%;
  max-width: 1062px;
  margin: 0 auto;
  padding: 0 0 60px 0;
  font-weight: 600;
  font-size: 32px;
  line-height: 1.2;
}

.PageServices .content-cards {
  order: 3;
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  justify-content: space-between;
  align-items: center;
  text-align: flex-start;
}

.PageServices .Content > img {
  order: 2;
  width: 100%;
}


.PageServices .content-cards h3 {
  margin: 64px 0 16px 0;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.2;
}

.PageServices .content-cards p {
  font-size: 14px;
  line-height: 1.2;
}

.PageServices .content-cards img {
  display: none;
}


@media(min-width: 800px) {
  .PageServices {
    padding: 120px 0;
    background: #fff;
  }

  .PageServices .Content > *:not(h2) {
    order: 3;
    flex: 1 1 43%;
    font-weight: 600;
    font-size: 48px;
    line-height: 57.6px;
  }

  .PageServices h2 {
    width: 100%;
    max-width: 1062px;
    margin: 0 auto;
    padding: 0 0 96px 0;
    font-weight: 600;
    font-size: 48px;
    line-height: 57.6px;
  }

  .PageServices .content-cards {
    order: 2;
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    justify-content: space-between;
    align-items: center;
    text-align: flex-start;
  }

  .PageServices .content-cards > div {
    margin: 0 41px 64px 0;
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    text-align: left;
  }


  .PageServices .content-cards h3 {
    flex: 3 4 75%;
    margin: 0 0 0 24px;
    font-weight: 500;
    font-size: 30px;
  }

  .PageServices .content-cards p {
    width: 100%;
    font-size: 16px;
    line-height: 19.2px;
  }

  .PageServices .content-cards img {
    display: initial;
  }
}