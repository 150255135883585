header.Content {
  height: 75px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.App-logo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  line-height: .9;
  font-size: 20px;
  font-weight: 800;
  color: #183561;
  cursor: default;
}

header.Content .intranet-button {
  display: none;
}

@media(min-width: 800px) {
  header.Content {
    width: 100%;
    height: 140px;
  }
  header.Content .intranet-button {
    display: block;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 26px;
  }
  .App-logo {
    line-height: 80%;
    font-size: 40px;
  }
}