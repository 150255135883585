.intranet-button {
  display: inline-block;
  font-size: 16px;
  line-height: 19.36px;
  font-weight: 600;
  text-transform: none;
  color: #fff;
  background-color: #183561;
  border: 0;
  border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
}

.intranet-button-outline {
  color: #183561;
  border: 2px solid #183561;
  background-color: #fff;
}

.intranet-button:hover {
  background-color: #18356190;
  background-repeat: no-repeat;
  background-position: center;
}

.intranet-button-outline:hover {
  border-color: #18356190;
  background-color: #efefef;
}

.intranet-button-round {
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}