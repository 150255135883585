.socialMedia {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  line-height: 19.36px;
  font-weight: 600;
  text-transform: none;
  color: #fff;
  border: 0;
}

.socialMedia > *:not(:last-child){
  margin: 0 8px 0 0;
}